import { useEffect } from "react";

const DownloadRedirect = () => {
  useEffect(() => {
    window.location.href = "https://download.bookr.co.in"; 
  }, []);

  return <p></p>; 
};

export default DownloadRedirect;